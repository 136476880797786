import(/* webpackMode: "eager", webpackExports: ["Content"] */ "/vercel/path0/app/content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Scripts"] */ "/vercel/path0/app/scripts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/react-bootstrap/esm/Col.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/react-bootstrap/esm/Row.js");
;
import(/* webpackMode: "eager", webpackExports: ["SessionErrorBoundary"] */ "/vercel/path0/src/components/errors/session.error-boundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserLink"] */ "/vercel/path0/src/components/links/links.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/topbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/_import.scss");
